<script>
export default {
    props: {
        label: String,
        value: String
    },
    data() {
        return {
            eventElement: null,
        };
    },
    mounted: async function () {
        this.eventElement = this.$refs['eventElement'];
    },
    methods: {
        maskPhone(value) {
            if (!value) return '';

            value = value.replace(/\D/g, ''); // Remove caracteres não numéricos
            if (value.length <= 10) {
                return value
                    .replace(/(\d{2})(\d{0,4})(\d{0,4})/, '($1) $2-$3')
                    .slice(0, 13);
            } else {
                return value
                    .replace(/(\d{2})(\d{1})(\d{0,4})(\d{0,4})/, '($1) $2$3-$4')
                    .slice(0, 15);
            }
        },
        focus() {
            const labelInput = this.eventElement.querySelector('label');
            this.eventElement.classList.add("focus_input");
            if (labelInput) {
                labelInput.classList.add("label_animation");
                labelInput.classList.add("color_focus");
            }
        },
        blur() {
            const labelInput = this.eventElement.querySelector('label');
            this.eventElement.classList.remove("focus_input");
            if (labelInput) {
                labelInput.classList.remove("color_focus");
                if (this.value.length === 0) labelInput.classList.remove("label_animation");
            }
        },
        updateValue(event) {
            const maskedValue = this.maskPhone(event.target.value);
            this.$emit('changeValue', maskedValue);
        },
    },
};
</script>

<template>
    <div class="input-text-theme" ref="eventElement">
        <label :notempty="value.length !== 0">{{ label }}</label>
        <input type="text" maxlength="16" @focus="focus" @blur="blur" :value="value" @input="updateValue" />
    </div>
</template>

<style scoped>
.input-text-theme {
    border: 1px solid #c7c7c7;
    width: 100%;
    border-radius: 8px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    gap: 10px;
    position: relative;
}

.input-text-theme:hover,
.input-text-theme.focus_input {
    border: 1px solid #0FC9A3;
}

.input-text-theme>label {
    position: absolute;
    font-family: Roboto;
    font-size: 0.75rem;
    color: black;
    background: white;
    top: 0px;
    left: 0px;
    transform: translate(15px, 16px) scale(1);
    transform-origin: left top 0px;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    width: auto;
    padding: 0px 5px;
}

.input-text-theme>label[icon-ready] {
    transform: translate(40px, 16px) scale(1);
}

.input-text-theme>label.label_animation,
.input-text-theme>label[notempty=true] {
    transform: translate(8px, -8px) scale(0.75);
}

.input-text-theme>label.color_focus {
    color: #0FC9A3;
}

.input-text-theme input {
    background: transparent;
    border: none;
    width: 100%;
    height: 100%;
    color: black;
    font-family: Roboto;
    font-size: 0.75rem;
    z-index: 10;
    padding: 0px 10px;
}

.input-text-theme input::placeholder {
    font-size: 0.85rem;
    color: black;
}

.input-text-theme .icon-search {
    margin-left: 10px;
    font-size: var(--icon-size);
    width: 20px;
    height: 20px;
}

.input-ionicon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 100%;
    margin-left: 5px;
}
</style>