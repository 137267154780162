<script setup>
import { db } from "../firebase.js";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import InputText from "../components/InputText.vue";
import InputTelefone from "../components/InputTelefone.vue";
import PlyrComponent from "../components/plyr-audio.vue";

const router = useRouter();
const questions = ref([]);

const isSmallScreen = ref([]);
// const selectedOption = ref(null);
const currentQuestionIndex = ref(0);
const answers = ref([]);
const numberResult = ref(null);
const result = ref(null);
const lead = ref({
  name: "",
  email: "",
  phone: "",
});
const leadCaptured = ref(false);
const checkScreenSize = () => {
  isSmallScreen.value = window.innerWidth < 767;
};

/*const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};*/

const currentQuestion = computed(
  () => questions.value[currentQuestionIndex.value]
);

onMounted(async () => {
  checkScreenSize.value;
  window.addEventListener("resize", checkScreenSize.value);
  try {
    const questionsCollection = collection(db, "questions");
    const querySnapshot = await getDocs(questionsCollection);
    let fetchedQuestions = [];
    querySnapshot.forEach((doc) => {
      fetchedQuestions.push(doc.data());
    });
    const shuffledQuestions = fetchedQuestions;
    questions.value = shuffledQuestions.slice(0, 30); // Pegando apenas as 5 primeiras perguntas depois de embaralhá-las
    questions.value.forEach(() => {
      answers.value.push({
        question: "",
        answer: "",
      });
    });
  } catch (error) {
    console.error("Error fetching questions: ", error);
  }
});

const updateName = (value) => {
  lead.value.name = value;
};

const updateEmail = (value) => {
  lead.value.email = value;
};

const updatePhone = (value) => {
  lead.value.phone = value;
};

const selectAnswer = (questionIndex, questionText, answerText) => {
  answers.value[questionIndex] = {
    question: questionText,
    answer: answerText,
  };
  console.log(answers.value[currentQuestionIndex.value] == answerText.value);
  console.log(answers.value[currentQuestionIndex.value]);
};

const previousQuestion = () => {
  if (currentQuestionIndex.value === 0) return;
  currentQuestionIndex.value--;
};

const nextQuestion = () => {
  if (answers.value[currentQuestionIndex.value].answer === "") {
    alert("Selecione uma opção.");
  } else {
    currentQuestionIndex.value++;
  }
  if (currentQuestionIndex.value >= questions.value.length) {
    checkAnswers();
  }
};

const checkAnswers = () => {
  let score = 0;
  answers.value.forEach((answerObj, index) => {
    if (answerObj.answer === questions.value[index].correct) {
      score++;
    }
  });

  const resultText = `Você acertou ${score} de ${questions.value.length} perguntas.`;
  const levelText = ` Seu nível estimado é: ${determineLevel(score).level}.`;

  result.value = resultText + levelText;
  numberResult.value = score;
};

const finalizeTest = async (resultToSave) => {
  try {
    const testId = router.currentRoute.value.params.id; // Pega o ID do teste a partir do path da URL
    const q = query(collection(db, "tests"), where("id", "==", testId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const DetermineLevel = determineLevel(numberResult.value);

      console.log(DetermineLevel);

      const dataToUpdate = {
        isFinished: true,
        result: resultToSave,
        numberResult: numberResult.value,
        answers: answers.value,
        lead: lead.value,
        proficiency: DetermineLevel.proficiency,
        level: DetermineLevel.level,
      };

      await updateDoc(docRef, dataToUpdate);
      await sendResultsToAPI(dataToUpdate);
      router.push({ name: "Thanks" });
    } else {
      console.error("No document found with the provided id:", testId);
    }
  } catch (error) {
    console.error("Error updating the test: ", error);
  }
};

const sendResultsToAPI = async (data) => {
  try {
    const response = await fetch(
      "https://zapmusic-english.bubbleapps.io/api/1.1/wf/english-test",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error sending results to API: ", error);
  }
};

const determineLevel = (score) => {
  if (score <= 9) return { level: "A1", proficiency: "Iniciante" };
  if (score <= 14) return { level: "A2", proficiency: "Iniciante" };
  if (score <= 19) return { level: "B1", proficiency: "Intermediário" };
  if (score <= 24) return { level: "B2", proficiency: "Intermediário" };
  if (score <= 28) return { level: "C1", proficiency: "Avançado" };
  return { level: "C2", proficiency: "Avançado" };
};

const captureLead = async () => {
  await addDoc(collection(db, "leads"), lead.value);
  leadCaptured.value = true;
  await finalizeTest(result.value);
};
</script>

<script>
export default {
  metaInfo: {
    title: "Second Impossible Coyote",
    meta: [
      {
        property: "og:title",
        content: "English Test",
      },
    ],
  },
};
</script>

<template>
  <div class="questionary-buttons">
    <div
      :currentIndexQuestion="currentQuestionIndex > 0"
      class="questionary-buttons-header"
    >
      <img alt="image" src="../assets/logo.webp" class="questionary-image" />
      <div
        v-if="currentQuestionIndex < questions.length"
        class="questionary-buttons-buttons"
      >
        <button
          v-if="currentQuestionIndex > 0"
          type="button"
          @click="previousQuestion"
          class="questionary-back"
        >
          <span v-if="!isSmallScreen">Voltar</span>
          <span v-else style="display: flex; justify-content: center"
            ><img
              alt="image"
              src="../assets/ic--sharp-arrow-back.png"
              style="width: 25px"
          /></span>
        </button>
        <button
          type="button"
          @click="nextQuestion(currentQuestionIndex)"
          class="questionary-next"
        >
          Próximo
        </button>
      </div>
    </div>
  </div>
  <div class="questionary-container">
    <div class="questionary-main">
      <div class="questionary-content">
        <!-- <div
        v-if="currentQuestionIndex < questions.length"
        class="questionary-header"
      >
      </div> -->

        <div
          v-if="currentQuestionIndex < questions.length"
          class="questionary-questionary"
        >
          <div class="questionary-content1">
            <!-- If currentQuestion.audio is present -->
            <span class="questionary-instruction">
              Question {{ currentQuestionIndex + 1 }} of {{ questions.length }}
            </span>
            <span class="questionary-instruction">
              {{ currentQuestion.instruction }}
            </span>
            <PlyrComponent
              v-if="currentQuestion.audio"
              :audio="currentQuestion.audio"
              class="questionary-listen-audio"
              :key="currentQuestion.text"
            ></PlyrComponent>
            <!-- <span class="questionary-question">{{ currentQuestion.text }}</span> -->
            <div
              class="questionary-question"
              v-html="currentQuestion.text"
            ></div>
            <img
              alt="image"
              src="../assets/my_answer.svg"
              class="questionary-image1"
            />
            <div class="questionary-options">
              <div
                v-for="(option, idx) in currentQuestion.options"
                :key="idx"
                @click="
                  selectAnswer(
                    currentQuestionIndex,
                    currentQuestion.text,
                    option
                  )
                "
                :name="'q' + currentQuestionIndex"
                :class="{
                  'questionary-alternative': true,
                  'active-option':
                    answers[currentQuestionIndex]?.answer === option,
                }"
              >
                <span class="questionary-text">{{ option }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="!leadCaptured" class="lead-container">
          <div class="lead-questionary">
            <span class="lead-text">Antes de prosseguir, se cadastre.</span>
            <div class="lead-content">
              <form @submit.prevent="captureLead" class="lead-form">
                <div class="lead-nome">
                  <InputText
                    label="Digite o seu nome"
                    :value="lead.name"
                    @changeValue="updateName"
                  ></InputText>
                </div>
                <div class="lead-email">
                  <InputText
                    label="Digite o seu e-mail"
                    :value="lead.email"
                    @changeValue="updateEmail"
                  ></InputText>
                </div>
                <div class="lead-email">
                  <InputTelefone
                    label="Digite o seu whatsapp"
                    :value="lead.phone"
                    @changeValue="updatePhone"
                  ></InputTelefone>
                </div>

                <button type="submit" class="lead-button">
                  Enviar e Ver Resultado
                </button>
              </form>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="result" class="lead-questionary">
            <img
              alt="image"
              src="../assets/logo.webp"
              class="questionary-image"
            />
            <img
              alt="image"
              src="../assets/happy_announcement.svg"
              class="questionary-image1"
            />
            {{ result }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
body {
  overflow: hidden;
}

#app {
  overflow: hidden;
}

.questionary-container {
  width: 100%;
  display: flex;
  overflow: auto;
  height: auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f7f7f7;
  padding-bottom: 100px;
  margin-top: 80px;
}

.questionary-main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.questionary-content {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: space-between;
}

.questionary-header {
  width: 80%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.questionary-image {
  width: 150px;
  align-self: flex-start;
  object-fit: cover;
}

.questionary-progress {
  position: relative;
  width: 70%;
  height: 10px;
  display: flex;
  max-width: 650px;
  min-width: 300px;
  margin-top: 25px;
  border-radius: 10px;
  background-color: #d0d0d0;
}

.questionary-step {
  position: absolute;
  width: 8.33%;
  height: 100%;
  display: flex;
  border-radius: 10px;
  background-color: #0fc9a3;
}

.questionary-load {
  height: 100%;
  border-left: 1px solid #e2e2e2;
}

.questionary-questionary {
  gap: 35px;
  width: 100%;
  display: flex;
  max-width: 700px;
  min-width: 340px;
  margin-top: 25px;
  align-items: center;
  padding-top: 30px;
  border-color: #c7c7c7;
  border-width: 1px;
  padding-left: 40px;
  align-content: space-around;
  border-radius: 10px;
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: 30px;
  justify-content: flex-start;
  background-color: #ffffff;
}

.questionary-content1 {
  width: 100%;
  display: flex;
  margin-top: 15px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.questionary-instruction {
  color: #717171;
  font-size: 1rem;
  align-self: flex-start;
  font-style: normal;
  font-family: Roboto;
  font-weight: 400;
}

.questionary-listen-audio {
  width: 100%;
  height: 45px;
  max-width: 100%;
  align-self: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
}

.questionary-question {
  color: #4b4b4b;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  align-self: flex-start;
  font-style: normal;
  margin-top: 10px;
  font-family: Roboto;
}

.questionary-image1 {
  width: 200px;
  margin-top: 35px;
  object-fit: contain;
}

.questionary-options {
  gap: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: flex-start;
}

.questionary-alternative {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 20px;
  align-items: center;
  border-color: #c7c7c7;
  border-width: 2px;
  border-radius: 10px;
  justify-content: center;
  background-color: #fafafa;
  transition: cubic-bezier(1, 0, 0, 1);
}

.questionary-alternative:hover {
  border-color: #0fc9a4c5;
}

.active-option {
  border-color: #0fc9a3;
  background-color: #0fc9a41e;
  color: #0fc9a3;
}
.questionary-alternative:hover,
.questionary-alternative[currentOption="true"]:hover {
  cursor: pointer;
}

.questionary-text {
  font-size: 1rem;
  align-self: flex-start;
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
}

.questionary-alternative1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 20px;
  align-items: center;
  border-color: #c7c7c7;
  border-width: 2px;
  border-radius: 10px;
  justify-content: center;
  background-color: #fafafa;
}

.questionary-text01 {
  font-size: 1rem;
  align-self: flex-start;
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
}

.questionary-alternative2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 20px;
  align-items: center;
  border-color: #c7c7c7;
  border-width: 2px;
  border-radius: 10px;
  justify-content: center;
  background-color: #fafafa;
}

.questionary-text02 {
  font-size: 1rem;
  align-self: flex-start;
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
}

.questionary-alternative3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 20px;
  align-items: center;
  border-color: #c7c7c7;
  border-width: 2px;
  border-radius: 10px;
  justify-content: center;
  background-color: #fafafa;
}

.questionary-text03 {
  font-size: 1rem;
  align-self: flex-start;
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
}

.questionary-footer {
  width: 100%;
  bottom: 0px;
  height: 120px;
  display: flex;
  position: fixed;
  box-shadow: 5px -5px 10px 0px rgba(212, 212, 212, 0.61);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}

.questionary-buttons {
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 360px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  background-color: #ffffff;
  box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.1);
}
.questionary-buttons-header {
  display: flex;
  justify-content: space-between;
  max-width: 700px;
  width: 100%;
  gap: 15px;
}
.questionary-buttons-buttons {
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
  width: 100%;
  gap: 15px;
}
.questionary-back {
  cursor: pointer;
  transition: ease;
  color: rgb(255, 255, 255);
  max-width: 130px;
  width: 130px;
  min-width: 45px;
  height: 45px;
  align-self: center;
  padding-top: 0px;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 10px;
  background-color: #c7c7c7;
  font-size: 16px;
}

.questionary-back:hover {
  background-color: #a8a8a8;
}

.questionary-next {
  color: #fdfdfd;
  width: 130px;
  transition: 0.3s;
  padding-top: 16px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 16px;
  border-radius: 10px;
  background-color: #0fc9a3;
}

.questionary-next:hover {
  color: #ffffff;
  background-color: #0fc9a380;
  cursor: pointer;
}

.lead-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.lead-questionary {
  gap: 35px;
  width: 100%;
  display: flex;
  max-width: 700px;
  min-width: 340px;
  margin-top: 25px;
  align-items: center;
  padding-top: 30px;
  border-color: #c7c7c7;
  border-width: 1px;
  padding-left: 40px;
  align-content: space-around;
  border-radius: 10px;
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: 30px;
  justify-content: flex-start;
  background-color: #ffffff;
}

.lead-text {
  font-size: 1.75rem;
  align-self: flex-start;
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
}

.lead-content {
  width: 100%;
  display: flex;
  margin-top: 15px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.lead-form {
  gap: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.lead-nome {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.lead-email {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.textLabels {
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
}

.inputs {
  width: 100%;
  height: 45px;
  border-style: solid;
  background-color: #ffffff;
  border-width: 1px;
  border-color: #c7c7c7;
  border-radius: 10px;
  font-family: Roboto;
  padding: 0px 10px;
}

.lead-button {
  color: #ffffff;
  height: 45px;
  font-style: normal;
  font-weight: 600;
  border-radius: 10px;
  background-color: #0fc9a3;
}

@media (max-width: 991px) {
  .questionary-header {
    width: 100%;
  }

  .questionary-image1 {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .questionary-back {
    width: 45px;
  }
}

@media (max-width: 479px) {
  .questionary-questionary {
    padding-left: 20px;
    padding-right: 20px;
  }

  .questionary-buttons {
    gap: 20px;
  }

  .lead-questionary {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
