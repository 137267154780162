import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import VueMeta from 'vue-meta'
import registerDirectives from './directives/registerDirectives.js';

// Criar uma instância do aplicativo
const app = createApp(App)

// Uso do Vue Router
app.use(router)

app.use(registerDirectives);
// Uso do vue-meta
/*app.use(VueMeta, {
    refreshOnceOnNavigation: true
})*/

// Montar a aplicação no elemento com o ID "app"
app.mount('#app')
