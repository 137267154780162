<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter();

import { db } from '../firebase.js';
import { collection, query, where, getDocs } from "firebase/firestore";

const result = ref(null);

onMounted(async () => {
  try {
    const testId = router.currentRoute.value.params.id;  // Pega o ID do teste a partir do path da URL
    
    const q = query(collection(db, "tests"), where("id", "==", testId));
    
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const docData = querySnapshot.docs[0].data();
      result.value = docData.result;  // Atualize a variável reativa com o valor do result do documento
    } else {
      console.error("No document found with the provided id:", testId);
    }
  } catch (error) {
    console.error("Error fetching the test result: ", error);
  }
});

</script>

<template>
  <div class="questionary-container">
    <div class="questionary-main">
      <div class="questionary-content">
        <div>
          <div class="lead-questionary">
            <img alt="image" src="../assets/logo.webp" class="questionary-image" />
            <img alt="image" src="../assets/happy_announcement.svg" class="questionary-image1" />
            {{ result }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
body {
  overflow: hidden;
}

#app {
  overflow: hidden;
}

.questionary-container {
  width: 100%;
  display: flex;
  overflow: auto;
  height: 100VH;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f7f7f7;
}

.questionary-main {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.questionary-content {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: space-between;
}

.questionary-image {
  width: 150px;
  align-self: flex-start;
  object-fit: cover;
}

.questionary-image1 {
  width: 200px;
  margin-top: 35px;
  object-fit: contain;
}

.lead-questionary {
  gap: 35px;
  width: 100%;
  display: flex;
  max-width: 700px;
  min-width: 340px;
  margin-top: 25px;
  align-items: center;
  padding-top: 30px;
  border-color: #c7c7c7;
  border-width: 1px;
  padding-left: 40px;
  align-content: space-around;
  border-radius: 10px;
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: 30px;
  justify-content: flex-start;
  background-color: #ffffff;
}

@media(max-width: 991px) {
  .questionary-image1 {
    margin-top: 40px;
  }
}

@media(max-width: 479px) {
  .lead-questionary {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
