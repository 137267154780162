import { createRouter, createWebHistory } from 'vue-router';
import { db } from './firebase.js';
import { collection, query, where, getDocs } from "firebase/firestore";

import NotFound from './views/not-found';
import Home from './views/home';
import Test from './views/test';
import Thanks from './views/Thanks';

const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: '404 - Not Found',
        component: NotFound,
    },
    {
        name: 'Home',
        path: '/',
        component: Home,
    },
    {
        name: 'Test',
        path: '/test/:id',
        component: Test,
    },
    {
        name: 'Thanks',
        path: '/thanks/:id',
        component: Thanks,
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.name === 'Test') {
        try {
            console.log("Fetching from Firestore...");

            const q = query(
                collection(db, "tests"),
                where("id", "==", to.params.id)  // Aqui estamos assumindo que o ID é "adrianofeioso", mas você pode alterar conforme necessário
            );

            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                console.log("Documents found!");

                querySnapshot.forEach((doc) => {
                    const result = doc.data();
                    console.log("Document data:", result);

                    if (result.isFinished) {
                        next({ name: 'Thanks' });
                    } else {
                        next();
                    }
                });
            } else {
                console.log("No documents found for the provided criteria.");
                next({ name: '404 - Not Found' });  // Você pode redirecionar para uma página de erro ou qualquer outra ação desejada
            }
        } catch (error) {
            console.error("Error getting documents: ", error);
            next(false);  // Isso interromperá a navegação atual
        }
    } else {
        next();  // Continue para a rota destino se não estivermos navegando para 'Test'
    }
});

export default router;
