import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAOilLCtWW0zvFKWz1iRLBDX3sQzDG2_Ng",
    authDomain: "zapmusic.firebaseapp.com",
    projectId: "zapmusic",
    storageBucket: "zapmusic.appspot.com",
    messagingSenderId: "453947145958",
    appId: "1:453947145958:web:9b37a1b5ae8aa6ac808527"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
