export const renderContent = {
    mounted (el, binding) {
        const { element } = binding.value;
        
        el.setAttribute('focus', "");
        
        el.addEventListener('click', () => {
            el.setAttribute("focus", "true");
        })

        el.querySelector(element).addEventListener('blur', () => {
            el.setAttribute("focus", "");
        })
    },
    updated (el, binding) {
        const { value, element } = binding.value;
        if(!el.getAttribute("focus")){ el.querySelector(element).innerText = value; }
    }
};