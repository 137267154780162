<script setup>
import { db } from '../firebase.js';
import { collection, addDoc } from "firebase/firestore";
// import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const startTest = async () => {
  const initialData = {
    id: createRandomId(10),
    isFinished: false,
    timestamp: new Date(),
  };
  await addDoc(collection(db, "tests"), initialData);
  goToTest(initialData.id);
};

const createRandomId = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let newId = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    newId += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return newId;
};

const goToTest = (id) => {
  router.push(`/test/${id}`);
};

</script>

<script>
export default {
  name: 'HomePage',
  metaInfo: {
    title: 'Second Impossible Coyote',
    meta: [
      {
        property: 'og:title',
        content: 'Second Impossible Coyote',
      },
    ],
  },
}
</script>

<template>
  <div class="inicio-container">
    <div class="inicio-main">
      <div class="inicio-inicio">
        <div class="inicio-container1">
          <img alt="image" src="../assets/logo.webp" class="inicio-image" />
          <h1 class="inicio-text">Teste sua proficiência em inglês!</h1>
          <span class="inicio-text01">
            <span>
              Com a ZapMusic, descubra exatamente onde você está em seu
              aprendizado de inglês e obtenha diretrizes claras para o próximo
              passo.
            </span>
            <span>
              <span><span></span></span>
              <span><span></span></span>
            </span>
            <span>
              <span><span></span></span>
              <span><span></span></span>
            </span>
          </span>
          <span class="inicio-text09">
            <span>
              <span>
                ✅ Identifique pontos fortes e áreas a melhorar
                <span></span>
              </span>
              <span><span></span></span>
            </span>
            <br />
            <br />
            <span>✅ Receba feedback instantâneo e dicas personalizadas</span>
            <br />
            <br />
            <span>✅ Prepare-se para exames e oportunidades internacionais</span>
            <br />
            <br />
            <span>✅ Adapta-se a todos os níveis, do básico ao avançado</span>
          </span>
          <div class="inicio-btn-group">
            <button class="inicio-button" @click="startTest">Iniciar agora</button>
          </div>
        </div>
        <img alt="image" src="../assets/online_test.svg" class="inicio-image1" />
      </div>
    </div>
  </div>
</template>

<style scoped>
button {
  border-width: 0px;
}

.inicio-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.inicio-main {
  width: 100%;
  display: flex;
  min-height: auto;
  align-items: center;
  flex-direction: column;
}

.inicio-inicio {
  width: 100%;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.inicio-container1 {
  display: flex;
  margin-right: 48px;
  flex-direction: column;
}

.inicio-image {
  width: 150px;
  align-self: flex-start;
  object-fit: cover;
}

.inicio-text {
  width: 100%;
  font-size: 3rem;
  margin-top: 15px;
  font-family: Roboto;
}

.inicio-text01 {
  width: 80%;
  font-family: Roboto;
}

.inicio-text09 {
  width: 100%;
  margin-top: 32px;
  font-family: Roboto;
  margin-bottom: 32px;
}

.inicio-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.inicio-button {
  color: #fdfdfd;
  transition: 0.3s;
  padding-top: 16px;
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 10px;
  padding-bottom: 16px;
  background-color: #0FC9A3;
}

.inicio-button:hover {
  color: #ffffff;
  background-color: #0FC9A380;
  cursor: pointer;
}

.inicio-image1 {
  width: 400px;
  object-fit: cover;
}

@media(max-width: 991px) {
  .inicio-inicio {
    flex-direction: column;
  }

  .inicio-container1 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: 32px;
    padding-right: 0px;
  }

  .inicio-text {
    padding-left: 32px;
    padding-right: 32px;
  }

  .inicio-text09 {
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media(max-width: 767px) {
  .inicio-inicio {
    padding-left: 32px;
    padding-right: 32px;
  }

  .inicio-text {
    padding-left: 16px;
    padding-right: 16px;
  }

  .inicio-text01 {
    padding-left: 16px;
    padding-right: 16px;
    width: 100%
  }

  .inicio-text09 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .inicio-image1 {
    width: 80%;

  }
}

@media(max-width: 479px) {
  .inicio-inicio {
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 32px;
  }

  .inicio-container1 {
    margin-bottom: 16px;
  }

  .inicio-btn-group {
    flex-direction: column;
  }
}
</style>
