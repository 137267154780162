<script setup>
import { defineProps, onMounted, ref, watch } from 'vue';
import Plyr from 'plyr';

const props = defineProps(['audio']);

const playerRef = ref(null);
const oldLink = ref(null)

onMounted(() => {
  playerRef.value = new Plyr('audio', {});
});

watch(props.audio, (newAudioLink) => {
  console.log("teste")
  if (oldLink.value !== newAudioLink) {
    console.log(oldLink.value);
    oldLink.value = newAudioLink;

    if (playerRef.value) {
      playerRef.value.destroy();
      playerRef.value = new Plyr('audio', {});
    }
  }
});
</script>

<template>
  <div class="plyr-container">
    <audio crossorigin playsinline>
      <source :src="props.audio">
    </audio>
  </div>
</template>

<style scoped>
:root {
  --plyr-color-main: #1ac266;
  
}</style>
