<template>
  <router-view />
</template>

<script>

export default {
  data() {
    return { result: null, test: null, itemExist: false, isFinished: false };
  },
}
</script>


<style>
* {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}

input {
  border: none;
  padding: 0px;
}

input:focus-visible {
  border: none;
  outline: none;
}

#app {
  padding: 0px;
  box-sizing: border-box;
  border-width: 0;
  border-style: none;
}

body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border-width: 0;
  border-style: none;
}
</style>
